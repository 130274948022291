<template lang="pug">
om-modal.product-tour-modal(
  name="product-tour"
  :width="465"
  @closeClicked="storeStatus({ status: 'skipped', tour: editorTourName })"
  :clickToClose="false"
)
  .d-flex.flex-column.align-items-center(slot="modal-body")
    product-tour-svg.svg
    .modal-title.h5.mt-3.mb-2 {{ $t('productTour.gettingStarted') }}
    .modal-description {{ $t('productTour.takeTour') }}
  template(slot="modal-footer")
    .buttons.d-flex.justify-content-center
      om-button.mr-3(ghost @click="skip") {{ $t('skip') }}
      om-button(primary @click="start") {{ $t('productTour.letsGo') }}
</template>

<script>
  import { TOUR_NAME as editorTourName } from '@/utils/productTours/editor';
  import ProductTourSvg from '@/editor/components/svg/ProductTour.vue';
  import { createNamespacedHelpers } from 'vuex';

  const { mapActions } = createNamespacedHelpers('productTour');

  export default {
    components: { ProductTourSvg },
    data: () => ({ editorTourName }),
    methods: {
      ...mapActions(['init', 'storeStatus']),
      skip() {
        this.storeStatus({ status: 'skipped', tour: editorTourName });
        this.$modal.hide('product-tour');
      },
      start() {
        this.storeStatus({ status: 'started', tour: editorTourName });
        this.$modal.hide('product-tour');
        setTimeout(() => this.init(), 350);
      },
    },
  };
</script>

<style lang="sass">
  .product-tour-modal
    .v--modal
      border-radius: 10px
      max-width: 465px
    .brand-modal.brand-modal-light
      background-color: transparent
    .brand-modal .brand-modal-header
      padding: 0.625rem 3.125rem 0.625rem
      background-color: transparent !important
    .brand-modal .brand-modal-footer
      padding: .25rem 3.125rem 2rem
    .modal-title
      font-size: 18px
      font-weight: 500
      text-align: center
    .modal-description
      text-align: center
      font-size: 14px
</style>
